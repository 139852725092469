const sendForm = () => {
    

const formSubmit = (idform, tagevent, event, redurl) => {
        

        event.preventDefault();

        var risposta = '';
        //var redirection = redurl;
        var idform = idform;
        var tagevent = tagevent; //CF7 ha bisogno di ricevere i dati così, se li passo come valori dentro formData non va... 
        var form = new FormData();

        form.append("cf_firstname", document.querySelector(".cf_firstname").value);
        form.append("cf_lastname", document.querySelector(".cf_lastname").value);
        form.append("cf_email", document.querySelector(".cf_email").value);
        form.append("cf_telephone", document.querySelector(".cf_telephone").value);
        form.append("cf_azienda", document.querySelector(".cf_azienda").value);
        form.append("cf_message", document.querySelector(".cf_message").value);
        form.append("cf_privacy", document.querySelector(".cf_privacy").value);

        if (document.querySelector(".cf_marketing").checked) {
            form.append("cf_marketing", document.querySelector(".cf_marketing").value);
        }

        if (document.querySelector(".cf_bombolo").value == '') {
            //honeybombolo
            window.dataLayer = window.dataLayer || [];
            axios({
                method: 'post',
                url: "https://atlashub.bigfive.cloud/form/wp-json/contact-form-7/v1/contact-forms/".concat(idform, "/feedback"),
                data: form,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }

            }).then(function (response) {
                if (response.data.status != 'validation_failed') {
                    window.dataLayer.push({
                        event: tagevent
                    });
                    //window.location.replace(redirection);
                }
                risposta = response.data.message;
                var respdiv = document.querySelector(".card-response");
                respdiv.innerHTML = risposta;

            });
        }
    }

    const form1 = document.getElementById("contact-form");
    const form1_id = document.getElementById("contact-form").dataset.cf7Id;
    const form1_tagm = document.getElementById("contact-form").dataset.tagm;

    form1.addEventListener("submit", function (event) {
        formSubmit(form1_id, form1_tagm, event, form1_ty)
    });

}
const formTriggers = () => {
    let formTrigger = document.querySelectorAll('.form-toggle');
    let formModal = document.querySelector('#floating-form');

    formTrigger.forEach(element => {
        element.addEventListener('click', () => {
            formModal.classList.toggle('active');
        })
    });

    window.addEventListener('mouseup', function(e){   
        const flyoutElement = document.querySelector(".floating-form");
        if (!flyoutElement.contains(e.target) && flyoutElement.classList.contains('active')){
            flyoutElement.classList.remove("active");
            formModal.classList.remove('active');
        }
    });
}

const Form = () => {
    sendForm();
    formTriggers();
}
export default Form;