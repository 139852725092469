const toggleMenu = () => {  
}


const Header = () => {
    if(document.querySelector('.site-header__menu-trigger')){
        toggleMenu();
    }
}

export default Header;