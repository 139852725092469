const isElementVisible = () => {  
    const el = document.querySelectorAll('.anim-section')

    el.forEach(element => {
        const observer = new window.IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                element.classList.add('visible')
                return
            }
                element.classList.remove('visible')
            }, {
                root: null,
                threshold: 0.3, // set offset 0.1 means trigger if atleast 10% of element in viewport
            })
        
            observer.observe(element);
    });
   
}

const scrollToSection = () => {  
    const el = document.querySelectorAll('.scrollToSection')



    el.forEach(element => {

        let target = document.querySelector(element.dataset.target);
        let headerOffset = document.querySelector(".site-header").offsetHeight;
        
        let elementPosition = target.getBoundingClientRect().top;
        let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

        element.addEventListener('click', function(){
            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
           });
        });
        
    });
   
}





const Common = () => {
    isElementVisible();
    scrollToSection();
}


export default Common;