import Swiper, {Pagination} from 'swiper';

const Swipers = () => {
	if(document.querySelector('#full-width-carousel-01')){


		new Swiper('#full-width-carousel-01', {
			modules: [Pagination],
			spaceBetween: 35,
			freeMode: false,
			slidesPerView: "auto",
			watchSlidesProgress : true,
			pagination: {
				el: '#full-width-carousel-01 .swiper-pagination',
				type: 'bullets',
				clickable: true
			}
		});
	}
	if(document.querySelector('#full-width-carousel-02')){


		new Swiper('#full-width-carousel-02', {
			modules: [Pagination],
			spaceBetween: 35,
			freeMode: false,
			slidesPerView: "auto",
			watchSlidesProgress : true,
			pagination: {
				el: '#full-width-carousel-02 .swiper-pagination',
				type: 'bullets',
				clickable: true
			}
		});
	}
	if(document.querySelector('#full-width-carousel-03')){


		new Swiper('#full-width-carousel-03', {
			modules: [Pagination],
			spaceBetween: 35,
			freeMode: false,
			slidesPerView: "auto",
			watchSlidesProgress : true,
			pagination: {
				el: '#full-width-carousel-03 .swiper-pagination',
				type: 'bullets',
				clickable: true
			}
		});
	}
}

export default Swipers;
