import Header from "./components/header";
import Common from "./components/common";
import Form from "./components/form";
import Swipers from "./components/swipers.js";
import AOS from 'aos';

Header();
Common();
Form();

AOS.init();
Swipers();
